import React, { useState, useEffect } from "react";
import { Accordion,Card,Button } from 'react-bootstrap';
import axios from "axios";
export default function Categories(props) {
    useEffect(() => {
        getProducts(props.id);
        
      },[]);
      const [Product, setProduct] = useState([])
      const getProducts = async (id) => {
        await axios
          .get(`//185.200.45.128/menuposition/${id}`, {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
          })
          .then((response) => {
      
            setProduct(response.data)
          })
    
          .catch((err) => {});
      };
  return (
    <Accordion defaultActiveKey="" >
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
             {props.category}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
            {Product.map(product =>( 
               <button onClick={()=>{props.basket(product)}} key={product.id}>{product.meal_name}</button>
              ))}  
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
  );
}
