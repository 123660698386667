import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Categories from "./Categories";
import { v4 as uuidv4 } from "uuid";

function App() {
  useEffect(() => {
    getCategories();
  }, []);
  const [Categoriers, setCategoriers] = useState([]);
  const getCategories = async () => {
    await axios
      .get("//185.200.45.128/menucategory",
      {
        "Content-Type": "application/json",
      
      })
      .then((response) => {
        setCategoriers(response.data);
      })

      .catch((err) => {
        console.log(err)
      });
  };
  const sha256 = (ascii) => {
    function rightRotate(value, amount) {
      return (value >>> amount) | (value << (32 - amount));
    }
    var mathPow = Math.pow;
    var maxWord = mathPow(2, 32);
    var lengthProperty = "length";
    var i, j; // Used as a counter across the whole file
    var result = "";
    var words = [];
    var asciiBitLength = ascii[lengthProperty] * 8;
    //* caching results is optional - remove/add slash from front of this line to toggle
    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
    // (we actually calculate the first 64, but extra values are just ignored)
    var hash = (sha256.h = sha256.h || []);
    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
    var k = (sha256.k = sha256.k || []);
    var primeCounter = k[lengthProperty];
    /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/
    var isComposite = {};
    for (var candidate = 2; primeCounter < 64; candidate++) {
      if (!isComposite[candidate]) {
        for (i = 0; i < 313; i += candidate) {
          isComposite[i] = candidate;
        }
        hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
        k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
      }
    }
    ascii += "\x80"; // Append Ƈ' bit (plus zero padding)
    while ((ascii[lengthProperty] % 64) - 56) ascii += "\x00"; // More zero padding
    for (i = 0; i < ascii[lengthProperty]; i++) {
      j = ascii.charCodeAt(i);
      if (j >> 8) return; // ASCII check: only accept characters in range 0-255
      words[i >> 2] |= j << (((3 - i) % 4) * 8);
    }
    words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
    words[words[lengthProperty]] = asciiBitLength;
    // process each chunk
    for (j = 0; j < words[lengthProperty]; ) {
      var w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
      var oldHash = hash;
      // This is now the undefinedworking hash", often labelled as variables a...g
      // (we have to truncate as well, otherwise extra entries at the end accumulate
      hash = hash.slice(0, 8);
      for (i = 0; i < 64; i++) {
        var i2 = i + j;
        // Expand the message into 64 words
        // Used below if
        var w15 = w[i - 15],
          w2 = w[i - 2];
        // Iterate
        var a = hash[0],
          e = hash[4];
        var temp1 =
          hash[7] +
          (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
          ((e & hash[5]) ^ (~e & hash[6])) + // ch
          k[i] +
          // Expand the message schedule if needed
          (w[i] =
            i < 16
              ? w[i]
              : (w[i - 16] +
                (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
                  w[i - 7] +
                  (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
                0);
        // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
        var temp2 =
          (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
          ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj
        hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
        hash[4] = (hash[4] + temp1) | 0;
      }
      for (i = 0; i < 8; i++) {
        hash[i] = (hash[i] + oldHash[i]) | 0;
      }
    }
    for (i = 0; i < 8; i++) {
      for (j = 3; j + 1; j--) {
        var b = (hash[i] >> (j * 8)) & 255;
        result += (b < 16 ? 0 : "") + b.toString(16);
      }
    }
    return result;
  };

  // Płatność
  const [Amount, setAmount] = useState(0);
  const [ID, setID] = useState("705218");
  const [Currency, setCurrency] = useState("PLN");
  const [Description, setDescription] = useState("Per-Tutti");
  const [Lang, setLang] = useState("pl");
  const [PIN, setPIN] = useState("qWgJDx6ulKzbPZImX7cQqCpJR5RPEuxW");
  const [ChannelGroups, setChannelGroups] = useState("K,T,M,I");
  const [Url, setUrl] = useState("https://google.pl/");
  const [Type, setType] = useState("0");
  const [IgnoreLastPaymentChannel, setIgnoreLastPaymentChannel] = useState(1);
  const [Code, setCode] = useState("");
  // Dane zamawiającego
  const [City, setCity] = useState("Dąbrowa Tarnowska");
  const [Street, setStreet] = useState("");
  const [HouseNumber, setHouseNumber] = useState("");
  const [Name, setName] = useState("");
  const [SecondName, setSecondName] = useState("");
  const [TelNumber, setTelNumber] = useState("");
  const [Email, setEmail] = useState("");
  // Stany applikacji
  const [OrderDisable, setOrderDisable] = useState("disabled");
  const [List, setList] = useState([]);
  // On Change
  const CityOn = (e) => {
    //setCity(e.target.value)
    setCity(City);
    setOrderDisable("disabled");
  };
  const StreetOn = (e) => {
    setStreet(e.target.value);
    setOrderDisable("disabled");
  };
  const HouseNumberOn = (e) => {
    setHouseNumber(e.target.value);
    setOrderDisable("disabled");
  };
  const NameOn = (e) => {
    setName(e.target.value);
    setOrderDisable("disabled");
  };
  const SecondNameOn = (e) => {
    setSecondName(e.target.value);
    setOrderDisable("disabled");
  };
  const TelNumberOn = (e) => {
    setTelNumber(e.target.value);
    setOrderDisable("disabled");
  };
  const EmailOn = (e) => {
    setEmail(e.target.value);
    setOrderDisable("disabled");
  };
  // Potwierdzenie zamówienia
  const ApplyOrder = () => {
    setCode(
      sha256(
        PIN +
          Lang +
          ID +
          Amount +
          Currency +
          Description +
          ChannelGroups +
          Url +
          Type +
          Name +
          SecondName +
          Email +
          IgnoreLastPaymentChannel
      )
    );
    setOrderDisable("");
  };
  // Koszyk add
  const OrderAdd = (iteam) => {
    setAmount(Amount + iteam.price);

    setList([
      ...List,
      {
        category: iteam.category,
        id: uuidv4(),
        meal_ingredients: iteam.meal_ingredients,
        meal_name: iteam.meal_name,
        price: iteam.price,
      },
    ]);
  };
  const Remove = (remove) => {
    console.log(List);
    let NewList = [];

    List.forEach((element) => {
      if (element.id !== remove.id) {
     NewList.push(element);
     
      }
    });
    setAmount(Amount-remove.price)
    setList(NewList)
    console.log(NewList);
  };
  if (OrderDisable.length > 0) {
    return (
      <div>
        <p>Adres</p>
        <input
          type="text"
          name="City"
          value={City}
          placeholder="Miejscowośc"
          onChange={CityOn}
        />
        <input
          type="text"
          value={Street}
          onChange={StreetOn}
          placeholder="Ulica"
        />
        <input
          type="text"
          value={HouseNumber}
          onChange={HouseNumberOn}
          placeholder="nr"
        />
        <input
          type="text"
          value={TelNumber}
          onChange={TelNumberOn}
          placeholder="Nr Telefonu"
        />
        <p>Dane</p>
        <input type="text" value={Name} onChange={NameOn} placeholder="Imie" />
        <input
          type="text"
          value={SecondName}
          onChange={SecondNameOn}
          placeholder="Nazwisko"
        />

        <input
          type="text"
          value={Email}
          onChange={EmailOn}
          placeholder="Email"
        />

        <button type="button" className="btn btn-success" onClick={ApplyOrder}>
          Podwierdz zamówienie
        </button>
        <div>
          {Categoriers.map((Categor) => (
            <Categories
              basket={OrderAdd}
              key={Categor.id}
              id={Categor.id}
              category={Categor.name}
            />
          ))}
        </div>
        <div>
          <p>Lista:</p>
          {List.map((iteam) => (
            <button key={iteam.id} onClick={() => Remove(iteam)}>
              {iteam.meal_name}
            </button>
          ))}
        </div>

        <p>Cena: {Amount}</p>
      </div>
    );
  } else {
    return (
      <div>
        <p>Adres dostawy</p>
        <p>{City}</p>
        <p>{Street + " " + HouseNumber}</p>
        <p>{Name + " " + SecondName}</p>
        <p>{TelNumber}</p>
        <p>{Email}</p>
        <p>Zamówienie</p>
        {List.map((item) => (
          <p>{item.meal_name}</p>
        ))}
        <button
          type="submit"
          disabled={OrderDisable}
          className="btn btn-success"
          onClick={() => {
            const form = document.getElementById("ToSubmit");
            if (true) {
              form.submit();
            }
          }}
        >
          Zapłać
        </button>
        <form
          id="ToSubmit"
          action="https://ssl.dotpay.pl/test_payment/"
          method="post"
          target="_blank"
          onSubmit={(e) => e.defaultPrevented}
        >
          <input
            type="hidden"
            value={Name}
            onChange={NameOn}
            placeholder="Imie"
          />
          <input
            type="hidden"
            value={SecondName}
            onChange={SecondNameOn}
            placeholder="Nazwisko"
          />

          <input
            type="hidden"
            value={Email}
            onChange={EmailOn}
            placeholder="Email"
          />
          <input type="hidden" name="id" value={ID} />
          <input type="hidden" name="amount" value={Amount} />
          <input type="hidden" name="currency" value={Currency} />
          <input type="hidden" name="description" value={Description} />
          <input type="hidden" name="lang" value={Lang} />
          <input type="hidden" name="channel_groups" value={ChannelGroups} />
          <input type="hidden" name="url" value={Url} />
          <input type="hidden" name="type" value={Type} />
          <input type="hidden" name="Name" value={Name} />
          <input type="hidden" name="firstname" value={Name} />
          <input type="hidden" name="lastname" value={SecondName} />
          <input type="hidden" name="email" value={Email} />
          <input
            type="hidden"
            name="ignore_last_payment_channel"
            value={IgnoreLastPaymentChannel}
          />
          <input type="hidden" name="chk" value={Code} />
        </form>
        <button
          type="submit"
          disabled={OrderDisable}
          className="btn btn-success"
          targer="_blank"
          onClick={() => {
            setOrderDisable("disable");
          }}
        >
          Wróć
        </button>
      </div>
    );
  }
}

export default App;
